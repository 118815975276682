import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

export const TextfieldBasic = ({
	isReadOnly,
	...otherProps
}) => {

	const configTextfield = {
		...otherProps,
		fullWidth: true,
		variant: "outlined",
		margin: "normal",
		size: "small",
		autoFocus: false,
		InputProps: { readOnly: isReadOnly }
	};

	return (
		<TextField
			{...configTextfield}
		/>
	);

};

export const TextfieldFormik = ({
	name,
	...otherProps
}) => {
	const [field, meta] = useField(name);

	const configTextfield = {
		...field,
		...otherProps,
		fullWidth: true,
		variant: "outlined",
		margin: "normal",
		size: "small",
		autoFocus: false,
	};

	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	return (
		<TextField
			{...configTextfield}
		/>
	);
};
